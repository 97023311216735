<template>
  <v-navigation-drawer
    app
    temporary
    fixed
    :width="'50vw'"
    :hide-overlay="!$root.mobile"
    :right="$root.lang != 'ar'"
    v-model="dialog"
    v-if="!$root.mobile"
    scrollable
  >
    <v-card elevation="0">
      <v-toolbar flat dark color="primary">
        <v-system-bar
          dark
          app
          color="primary"
          :height="$root.sysbarheight"
          v-if="$root.showBar"
        >
        </v-system-bar>
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%">
        <div v-if="isDoneLoading">
          <div
            class=" mt-5 mb-5"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="userbox p-8 mx-3">
              <v-avatar class="mb-2" color="primary" size="60px">
                <v-img
                  v-if="details.doctor.avatar"
                  :src="details.doctor.avatar.data.thumbnails[0].url"
                  contain
                />
                <div
                  v-else
                  style="text-transform: uppercase;width:100%"
                  class="white--text font-size-h4"
                >
                  {{ details.doctor.first_name.charAt(0) }}
                </div>
              </v-avatar>
              <div class="font-size-h6">{{ $t("MF.summery.doctor") }}</div>
              <div class="font-size-h6">
                {{ details.doctor.first_name }} {{ details.doctor.last_name }}
              </div>
            </div>

            <v-avatar
              size="40px"
              color="#f2f7fd"
              v-if="details.general.type == 'clinic'"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="user-arrows"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-avatar>
            <v-avatar
              size="40px"
              color="#f2f7fd"
              v-if="details.general.type == 'video'"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="video"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-avatar>
            <div class="userbox p-8 mx-3">
              <v-avatar class="mb-2" color="primary" size="60px">
                <v-img
                  v-if="details.pateint.avatar"
                  :src="details.pateint.avatar.data.thumbnails[0].url"
                  contain
                />
                <div
                  v-else
                  style="text-transform: uppercase;width:100%"
                  class="white--text font-size-h4"
                >
                  {{ details.pateint.first_name.charAt(0) }}
                </div>
              </v-avatar>
              <div class="font-size-h6">{{ $t("MF.summery.patient") }}</div>
              <div class="font-size-h6">
                {{ details.pateint.first_name }} {{ details.pateint.last_name }}
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ $t("MF.summery.details") }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="details.general.created_on">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.createdon") }}
                      </td>
                      <td class="font-size-h6">
                        {{
                          details.general.created_on
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY hh:mm A"
                            )
                        }}
                      </td>
                    </tr>
                    <tr v-if="details.general.date_time">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.appdate") }}
                      </td>
                      <td class="font-size-h6">
                        {{
                          details.general.date_time
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY hh:mm A"
                            )
                        }}
                      </td>
                    </tr>
                    <tr v-if="details.general.referred_by">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.referredby") }}
                      </td>
                      <td>
                        <AvatarSM
                          :user="details.general.referred_by"
                        ></AvatarSM>
                      </td>
                    </tr>
                    <tr v-if="details.general.type">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.type") }}
                      </td>
                      <td class="font-size-h6">{{ details.general.type }}</td>
                    </tr>
                    <tr v-if="details.general.reason">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.reason") }}
                      </td>
                      <td class="font-size-h6">{{ details.general.reason }}</td>
                    </tr>
                    <tr v-if="details.general.rating">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.rating") }}
                      </td>
                      <td>
                        <v-rating
                          color="primary"
                          length="5"
                          readonly
                          :value="details.general.rating"
                        ></v-rating>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>

          <!-- history -->
          <div class="row mb-3" v-if="details.changes.history.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[0].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in details.changes.history" :key="item.id">
                      <td>{{ item.history_type.type }}</td>
                      <td>{{ item.history_body }}</td>
                      <td>
                        {{
                          item.date
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY"
                            )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>

          <!-- vitals -->
          <div class="row mb-3" v-if="details.changes.vitals.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[1].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in details.changes.vitals" :key="item.id">
                      <td>{{ item.vital_type.name }}</td>
                      <td :style="'color:' + item.health">
                        {{ item.reading }} {{ item.vital_type.suffix }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <!-- diagnoses -->
          <div class="row mb-3" v-if="details.changes.diagnoses.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[2].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in details.changes.diagnoses"
                      :key="item.id"
                    >
                      <td>{{ item.icd.Description }}</td>
                      <td>{{ item.examination }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <!-- prescriptions -->
          <div class="row mb-3" v-if="details.changes.prescriptions.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[3].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table
                v-for="pres in details.changes.prescriptions"
                :key="pres.id"
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>{{ $t("MF.summery.notes") }}</td>
                      <td>{{ pres.notes }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("MF.summery.med") }}</td>
                      <td>
                        <div
                          v-for="(m, index) in pres.medication"
                          :key="m.id"
                          class=""
                        >
                          <span class="mb-1 font-size-lg">
                            {{ m.quantity }} {{ m.type }} of {{ m.dosage }}
                            {{ m.medicine.name }}
                          </span>
                          <br />
                          <span class="font-size-sm"
                            ><span v-for="(t, index) in m.times" :key="t"
                              >{{ t }}
                              <span v-if="index < m.times.length - 1"
                                >,
                              </span></span
                            ></span
                          >
                          <span class="font-size-sm">{{ m.frequancy }}</span>
                          <br />
                          <span class="font-size-sm"
                            >Expires on: {{ m.end_date }}</span
                          >
                          <v-divider
                            v-if="index < pres.medication.length - 1"
                          ></v-divider>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center mt-5 mb-5"
          style="width: 100%; margin-right: auto; margin-left: auto"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
  <v-bottom-sheet v-model="dialog" v-else scrollable>
    <v-card :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'">
      <v-toolbar flat>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%">
        <div v-if="isDoneLoading">
          <div
            class=" mt-5 mb-5"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="userbox p-8 mx-3">
              <v-avatar class="mb-2" color="primary" size="60px">
                <v-img
                  v-if="details.doctor.avatar"
                  :src="details.doctor.avatar.data.thumbnails[0].url"
                  contain
                />
                <div
                  v-else
                  style="text-transform: uppercase;width:100%"
                  class="white--text font-size-h4"
                >
                  {{ details.doctor.first_name.charAt(0) }}
                </div>
              </v-avatar>
              <div class="font-size-h6">{{ $t("MF.summery.doctor") }}</div>
              <div class="font-size-h6">
                {{ details.doctor.first_name }} {{ details.doctor.last_name }}
              </div>
            </div>

            <v-avatar
              size="40px"
              color="#f2f7fd"
              v-if="details.general.type == 'clinic'"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="user-arrows"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-avatar>
            <v-avatar
              size="40px"
              color="#f2f7fd"
              v-if="details.general.type == 'video'"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="video"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-avatar>
            <div class="userbox p-8 mx-3">
              <v-avatar class="mb-2" color="primary" size="60px">
                <v-img
                  v-if="details.pateint.avatar"
                  :src="details.pateint.avatar.data.thumbnails[0].url"
                  contain
                />
                <div
                  v-else
                  style="text-transform: uppercase;width:100%"
                  class="white--text font-size-h4"
                >
                  {{ details.pateint.first_name.charAt(0) }}
                </div>
              </v-avatar>
              <div class="font-size-h6">{{ $t("MF.summery.patient") }}</div>
              <div class="font-size-h6">
                {{ details.pateint.first_name }} {{ details.pateint.last_name }}
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ $t("MF.summery.details") }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="details.general.created_on">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.createdon") }}
                      </td>
                      <td class="font-size-h6">
                        {{
                          details.general.created_on
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY hh:mm A"
                            )
                        }}
                      </td>
                    </tr>
                    <tr v-if="details.general.date_time">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.appdate") }}
                      </td>
                      <td class="font-size-h6">
                        {{
                          details.general.date_time
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY hh:mm A"
                            )
                        }}
                      </td>
                    </tr>
                    <tr v-if="details.general.referred_by">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.referredby") }}
                      </td>
                      <td>
                        <AvatarSM
                          :user="details.general.referred_by"
                        ></AvatarSM>
                      </td>
                    </tr>
                    <tr v-if="details.general.type">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.type") }}
                      </td>
                      <td class="font-size-h6">{{ details.general.type }}</td>
                    </tr>
                    <tr v-if="details.general.reason">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.reason") }}
                      </td>
                      <td class="font-size-h6">{{ details.general.reason }}</td>
                    </tr>
                    <tr v-if="details.general.rating">
                      <td class="font-weight-boldest font-size-h6">
                        {{ $t("MF.summery.rating") }}
                      </td>
                      <td>
                        <v-rating
                          color="primary"
                          length="5"
                          readonly
                          :value="details.general.rating"
                        ></v-rating>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>

          <!-- history -->
          <div class="row mb-3" v-if="details.changes.history.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[0].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in details.changes.history" :key="item.id">
                      <td>{{ item.history_type.type }}</td>
                      <td>{{ item.history_body }}</td>
                      <td>
                        {{
                          item.date
                            | moment(
                              "timezone",
                              getTimeZone(),
                              "dddd, MMMM Do YYYY"
                            )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>

          <!-- vitals -->
          <div class="row mb-3" v-if="details.changes.vitals.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[1].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in details.changes.vitals" :key="item.id">
                      <td>{{ item.vital_type.name }}</td>
                      <td :style="'color:' + item.health">
                        {{ item.reading }} {{ item.vital_type.suffix }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <!-- diagnoses -->
          <div class="row mb-3" v-if="details.changes.diagnoses.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[2].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in details.changes.diagnoses"
                      :key="item.id"
                    >
                      <td>{{ item.icd.Description }}</td>
                      <td>{{ item.examination }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <!-- prescriptions -->
          <div class="row mb-3" v-if="details.changes.prescriptions.length > 0">
            <div class="col-12 pb-0">
              <span class="font-size-h6">{{ items[3].name }}</span>
              <v-divider class="mb-0"></v-divider>
            </div>
            <div class="col-12 pt-0">
              <v-simple-table
                v-for="pres in details.changes.prescriptions"
                :key="pres.id"
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>{{ $t("MF.summery.notes") }}</td>
                      <td>{{ pres.notes }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("MF.summery.med") }}</td>
                      <td>
                        <div
                          v-for="(m, index) in pres.medication"
                          :key="m.id"
                          class=""
                        >
                          <span class="mb-1 font-size-lg">
                            {{ m.quantity }} {{ m.type }} of {{ m.dosage }}
                            {{ m.medicine.name }}
                          </span>
                          <br />
                          <span class="font-size-sm"
                            ><span v-for="(t, index) in m.times" :key="t"
                              >{{ t }}
                              <span v-if="index < m.times.length - 1"
                                >,
                              </span></span
                            ></span
                          >
                          <span class="font-size-sm">{{ m.frequancy }}</span>
                          <br />
                          <span class="font-size-sm"
                            >Expires on: {{ m.end_date }}</span
                          >
                          <v-divider
                            v-if="index < pres.medication.length - 1"
                          ></v-divider>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center mt-5 mb-5"
          style="width: 100%; margin-right: auto; margin-left: auto"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import ApiService from "@/core/services/api.service";
import AvatarSM from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "Summery",
  props: ["via", "dialog"],
  components: { AvatarSM },
  data() {
    return {
      error: "",
      title: this.$t("MF.summery.loading"),
      details: null,
      isDoneLoading: false,
      items: [
        { id: 1, name: this.$t("MF.medicalHistoryLabel"), icon: "mdi-history" },
        { id: 2, name: this.$t("MF.vitalslabel"), icon: "mdi-pulse" },
        { id: 3, name: this.$t("MF.diagnosesLabel"), icon: "mdi-doctor" },
        { id: 4, name: this.$t("MF.prescriptionLabel"), icon: "mdi-pill" },
        { id: 5, name: this.$t("MF.filesLabel"), icon: "mdi-folder-account" },
        { id: 6, name: this.$t("MF.notesLabel"), icon: "mdi-file" }
      ]
    };
  },
  mounted() {
    // console.log("mounted summery");
  },
  watch: {
    dialog: function(newDialog) {
      // console.log(newDialog, oldDialog);
      if (newDialog) {
        this.getDetails();
      }
    }
  },
  methods: {
    close() {
      // console.log('closing dialog');
      this.details = null;
      this.isDoneLoading = false;
      this.title = this.$t("MF.summery.loading");
      this.$emit("closeClicked", false);
    },
    async getDetails() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get("custom", "mf/getActionSummery/" + this.via)
        .then(({ data }) => {
          // console.log(data.data);
          this.details = data.data;
          this.isDoneLoading = true;
          // console.log(data.data.type);
          if (data.data.type == "Appointment") {
            this.title = this.$t("MF.summery.titleA");
          } else if (data.data.type == "Consulatation") {
            this.title = this.$t("MF.summery.titleC");
          }
          if (data.data.general.date_time) {
            this.title +=
              " " +
              this.$moment(data.data.general.date_time)
                .tz(this.getTimeZone())
                .format("DD/MM/YYYY hh:mm A");
          } else {
            this.title +=
              " " +
              this.$moment(data.data.general.created_on)
                .tz(this.getTimeZone())
                .format("DD/MM/YYYY hh:mm A");
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getTimeZone() {
      return this.$util.getTimezone();
    }
  },
  computed: {}
};
</script>
