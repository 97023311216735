<template>
  <div class="d-flex align-items-center">
    <span
      v-if="user.avatar"
      class="symbol symbol-circle symbol-45 symbol-light-primary mx-3"
    >
      <v-img
        contain
        max-height="45px"
        max-width="45px"
        style="border-radius: 50%;"
        lazy-src="/media/users/100_1.jpg"
        :src="user.avatar.data.thumbnails[0].url"
      ></v-img>
    </span>
    <span
      v-else
      class="symbol symbol-circle symbol-45 symbol-light-primary mx-3"
    >
      <span
        class="symbol-label font-size-h5 font-weight-bold"
        style="text-transform: uppercase;"
        >{{ user.first_name.charAt(0) }}</span
      >
    </span>
    <div class="d-flex flex-column text-left">
      <span class="text-dark-75 font-weight-bolder font-size-lg"
        >{{ user.first_name }} {{ user.last_name }}</span
      >
    </div>
  </div>
</template>

<script>
//import ApiService from "@/core/services/api.service";

export default {
  name: "Avatar",
  props: ["user"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
